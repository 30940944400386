import React from "react"
import FeaturedImagePage from "../common/FeaturedImagePage"
import Link from "../common/Link"
import H1 from "../layout/H1"
import Kursfakta from "./Kursfakta"

export default function SingleKurs({ kurs }) {
  const { title, content, featuredImage, kursfakta } = kurs
  const image = featuredImage?.node.localFile.childImageSharp
  return (
    <div className="min-h-screen  flex flex-col items-center justify-start">
      <div className=" w-full relative bg-gradient-to-t bg-black">
        {image && <FeaturedImagePage image={image} />}
        <div className="absolute top-0 w-full h-full flex flex-col justify-center ">
          <div className="px-4 md:px-24 max-w-screen-2xl mx-auto w-full">
            <H1 text={title} />
          </div>
        </div>
      </div>
      <div className=" relative px-4 md:px-24 max-w-screen-2xl w-full space-y-8 py-8 md:py-16">
        {/* <div className="font-medium text-gray-400 text-base">
          <Link to="/utbildningar/allmanna-kurser">&larr; Allmänna kurser</Link>
        </div> */}
        <div className="page-content flex flex-col md:flex-row gap-4 md:gap-16 border-primary border-t-4">
          <div className="w-full md:w-2/3 pt-8">
            <h2>Om kursen</h2>
            <div
              className="wp-content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <div className="w-full md:w-1/3">
            {(kursfakta.kurslangd || kursfakta.studiotakt) && (
              <div className="bg-primary/20 p-8">
                <Kursfakta kursfakta={kursfakta} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
