import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SingleKurs from "../components/kurser/SingleKurs"
import Seo from "../components/seo"
export default function category({ data }) {
  const kurs = data.wpKurs
  return (
    <>
      <Layout>
        <Seo title={"Utbildning - " + kurs.title} />
        <div className="h-full w-full">
          <SingleKurs kurs={kurs} />
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpKurs(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      kursfakta {
        kortText
        kurslangd
        materialavgift
        gomKnappKursfakta
        studietakt
        terminstider {
          terminstid
        }
        kontaktpersoner {
          epost
          namn
        }
      }
    }
  }
`
