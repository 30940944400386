import React from "react"
import Link from "../common/Link"

export default function Kursfakta({ kursfakta }) {
  const {
    kurslangd,
    materialavgift,
    studietakt,
    kontaktpersoner,
    terminstider,
    gomKnappKursfakta,
  } = kursfakta
  return (
    <div className="space-y-2">
      <div>
        <em>Kurslängd: </em>
        {kurslangd}
      </div>
      <div>
        <em>Kurstider: </em>
        <ul className="list-none">
          {terminstider?.map(post => {
            return <li key={post.terminstid}>{post.terminstid}</li>
          })}
        </ul>
      </div>
      <div>
        {materialavgift && (
          <>
            <em>Materialavgift: </em>
            {materialavgift}
          </>
        )}
      </div>
      <div>
        {studietakt && (
          <>
            <em>Studietakt: </em>
            {studietakt}
          </>
        )}
      </div>
      <div>
        {kontaktpersoner && (
          <div>
            <em>
              {kontaktpersoner && kontaktpersoner.length > 1
                ? "Kontaktpersoner:"
                : "Kontaktperson:"}
            </em>
            <ul className="list-none ">
              {kontaktpersoner?.map(post => {
                return (
                  <li key={post.namn}>
                    <a href={"mailto:" + post.epost} className="underline">
                      {post.namn}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
      {gomKnappKursfakta !== true && (
        <div className="pt-4">
          <Knapp text="Ansök här" url="/ansok" />
        </div>
      )}
    </div>
  )
}

function Knapp({ text, url }) {
  return (
    <>
      <a
        href={url}
        title={text}
        className="px-3 py-2 bg-primary text-white font-bold"
      >
        {text}
      </a>
    </>
  )
}
